<template>
  <vue-scroll>
    <v-card >
        <v-toolbar rounded 
          color="primary"
          class="mb-8"
          dark
        >
          <v-toolbar-title >Fêtes configuration</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="d-flex justify-space-between mx-2 mb-4">
          <div class="d-flex flex-column ml-2" style="width: 48%">
            <!-- nom de la config -->
            <v-text-field
              label="Nom de la configuration"
              v-model="creatingMessage.title"
              :rules="[(v) => !!v || 'Ce champ est requis']"
            ></v-text-field>
            <!-- date de début et de fin -->
            <v-menu absolute offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    :value="getStart"
                    label="Date de début"
                    :rules="[(v) => !!v || 'Ce champ est requis']"
                >
                  <template v-slot:append>
                    <v-btn icon small @click="creatingMessage.start = null">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                  v-model="creatingMessage.start"
                  :locale="$i18n.locale"
                  color="primary"
              ></v-date-picker>
  
            </v-menu>
            <v-menu absolute offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    :value="getEnd"
                    label="Date de fin"
                    :rules="[(v) => !!v || 'Ce champ est requis']"
                >
                  <template v-slot:append>
                    <v-btn icon small @click="creatingMessage.end = null">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                  v-model="creatingMessage.end"
                  :locale="$i18n.locale"
                  color="primary"
              ></v-date-picker>
  
            </v-menu>
            <!-- image à telecharger -->
            <v-file-input
              placeholder="Choisir une image"
              prepend-icon="mdi-camera"
              accept="image/*"
              @change="handleImageChange"
              :value="creatingMessage.image"
              type="file"
              name="file"
              :rules="[(v) => !!v || 'Ce champ est requis']"
            ></v-file-input>
            <!-- afficher l'image -->
            <v-img
              :src="creatingMessage.newLogoPreview"
              height="200px"
              width="100%"
              class="mt-4"
            ></v-img>
          </div>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column" style="width: 48%">
            <client-picker v-model="creatingMessage.list" 
            ></client-picker>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  @click="closeModal">Annuler</v-btn>
          <v-btn color="success"
           @click="saveAndCloseModal"
            :disabled="!isFormValid"
           >Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </vue-scroll>
    
</template>

<script>
import moment from 'moment';
// import loginImage from "@/Assets/Images/Interface/signatures-fete.png";
import FetesService from "../../../../Services/SupportVision/FetesService";
import Images from '@/Utils/Images';
import { mapMutations } from "vuex";

export default {
  name: 'fetesCreate',
  components: {
    ClientPicker: () => import("@/Components/Commons/Inputs/Client/ClientPicker"),
  },
  computed: {
    ...mapMutations(['setFetes']),
    getStart() {
      return this.creatingMessage.start
          ? moment(this.creatingMessage.start, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
          : ''
    },
    getEnd() {
      return this.creatingMessage.end
          ? moment(this.creatingMessage.end, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
          : ''
    },
    // verifie si les champs sont remplis
    isFormValid() {
      return this.creatingMessage.title && this.creatingMessage.list.length > 0 && this.creatingMessage.start && this.creatingMessage.end;
    },
  },
  data: () => ({
    dialog: false,
    // loginImage: loginImage,
    creatingMessage: {
      title: "",
      list: [],
      start: null,
      end: null,
      image: null,
      newLogoPreview: null,
    },
  }),
  methods: { 
    closeModal() {
      this.$emit('close-create');
    },
    async handleImageChange(file) {
      if (file) {
        let b64 = await Images.toBase64(file);
        this.creatingMessage.newLogoPreview = b64;
        this.creatingMessage.image = file;
      }
    },
    async saveAndCloseModal() {
      await FetesService.postFete(this.creatingMessage);
      this.$emit('fete-created');
      this.$emit('close-create');
    },
  },
};
</script>
