import Api from "@/Services/Api";

export default {
    async getFetes() {
    let response = await Api().get("/support-vision/fetes")
    return response.data
    },
    async postFete(data) {
        let response = await Api().post("/support-vision/fetes", {fete: data})
        return response.data
    },
    async putFete(data) {
        let response = await Api().put("/support-vision/fetes", {fete: data})
        return response
    },
    async removeFete(id) {
        let response = await Api().delete(`/support-vision/fetes/${id}`)
        return response
    }
}