<template>
  <v-container fluid>
    <v-toolbar rounded>
      <v-toolbar-title class="text-h5" elevation="20">Fêtes</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="fetesCreate">
        <v-btn @click="openModal" color="primary" small>Créer</v-btn>
      </slot>
    </v-toolbar>

    <v-text-field 
      class="mt-8 mb-4"
      dense 
      v-model="search" 
      append-icon="mdi-magnify" 
      label="Search" 
      single-line
      hide-details
    >
    </v-text-field>

    <v-data-table 
      :headers="headers" 
      :items="fetes" 
      :items-per-page="5" 
      class="elevation-1 mt-4"
      :search="search"
    >
      <template v-slot:item.title="{ item }">
        {{ item.title }}
      </template>
      <template v-slot:item.list="{ item }">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template>
                <span>Liste des bases</span>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(base, index) in item.list" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ base.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-slot:item.start="{ item }">
        {{ item.start | formatDate }}
      </template>
      <template v-slot:item.end="{ item }">
        {{ item.end | formatDate }}
      </template>
      <template v-slot:item.img="{ item }">
        <v-img :src="item.img" width="400" height="100"></v-img>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- menu -->
        <v-menu 
          :close-on-content-click="false"
          transition="slide-x-transition"
          bottom
          left 
          ref="editMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openEditMenu(item)">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <v-card width="1000">
            <v-card-title>
              <span class="text-h5">Editer la configuration</span>
            </v-card-title>

            <v-card-text>
              <div class="d-flex justify-space-between mx-2 mb-4">
                <div class="d-flex flex-column ml-2" style="width: 48%">
                  <v-text-field v-model="editedItem.title" label="Titre"></v-text-field>
                    <v-menu absolute offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-bind="attrs" v-on="on" :value="getStart" label="Date de début">
                        <template v-slot:append>
                          <v-btn icon small @click="editedItem.start = null">
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </template>

                    <v-date-picker v-model="editedItem.start" :locale="$i18n.locale" color="primary"></v-date-picker>

                    </v-menu>

                  <v-menu absolute offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-bind="attrs" v-on="on" :value="getEnd" label="Date de fin">
                        <template v-slot:append>
                          <v-btn icon small @click="editedItem.end = null">
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </template>

                    <v-date-picker v-model="editedItem.end" :locale="$i18n.locale" color="primary"></v-date-picker>

                  </v-menu>

                    <v-file-input
                      placeholder="Choisir une image"
                      prepend-icon="mdi-camera"
                      accept="image/*"
                      @change="handleImageChange"
                      :value="editedItem.image"
                      type="file"
                      name="file"
                    ></v-file-input>
                    <!-- afficher l'image -->
                    <v-img
                      :src="editedItem.img"
                      height="200px"
                      width="100%"
                    ></v-img>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="d-flex flex-column" style="width: 48%">
                      <client-picker v-model="editedItem.list"></client-picker>
                  </div>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn icon @click="deleteModaleOpen(item)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- modale create -->
    <v-dialog v-model="dialog" max-width="90%">
      <fetesCreate @close-create="closeModal" @fete-created="refreshData"/>
    </v-dialog>
    <!-- modale delete -->
    <v-dialog v-model="dialogDelete" width="30%">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation de suppression</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir supprimer cette configuration ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDelete = false">Annuler</v-btn>
          <v-btn color="error" @click="deleteFete">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </v-container>
</template>

<script>

import fetesCreate from './fetesCreate.vue';
import { mapState, mapMutations } from 'vuex';
import FetesService from "../../../../Services/SupportVision/FetesService";
import moment from 'moment';
import Images from '@/Utils/Images';

export default {
  name: 'fetes',
  components: {
    fetesCreate,
    ClientPicker: () => import("@/Components/Commons/Inputs/Client/ClientPicker"),
  },
  computed: {
    ...mapState(['fetes']),
    ...mapMutations(['setFetes']),
    getStart() {
      return this.editedItem.start
        ? moment(this.editedItem.start, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
        : ''
    },
    getEnd() {
      return this.editedItem.end
        ? moment(this.editedItem.end, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
        : ''
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      { text: 'Titre', value: 'title' },
      { text: 'Liste des bases', value: 'list' },
      { text: 'Date de début', value: 'start' },
      { text: 'Date de fin', value: 'end' },
      { text: 'Image', value: 'img' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedItem: {
      title: "",
      list: [],
      start: null,
      end: null,
      image: null,
      newLogoPreview: null,
      id: null,
    },
  }),
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    deleteModaleOpen(item) {
      this.dialogDelete = true;
      this.editedItem.id = item.id;
    },
    async refreshData() {
      const res = await FetesService.getFetes();
    // verifie si la fete existe deja via l'id sinon on la push
    for (const f of res) {
      let index = this.fetes.findIndex((item) => item.id === f._id);
      if (index === -1) {
        this.fetes.push({
          id: f._id,
          title: f.fete.title,
          start: f.fete.start,
          end: f.fete.end,
          img: f.fete.newLogoPreview,
          list: f.fete.list,
        })
      }
    }
    },
    async deleteFete() {
      await FetesService.removeFete(this.editedItem.id);
      const index = this.fetes.findIndex((item) => item.id === this.editedItem.id);
      if (index !== -1) {
        this.fetes.splice(index, 1);
      }
      this.dialogDelete = false;
    },
    close() {
      this.editedItem = {};
      this.$refs.editMenu.isActive = false;
    },
    async openEditMenu(item) {
      this.editedItem = item;
    },
    async save() {
      const requestData = {
        ...this.editedItem,
        __ob__: undefined,
      };
      await FetesService.putFete(requestData);
      this.$refs.editMenu.isActive = false;
    },
    async handleImageChange(file) {
      if (file) {
        let b64 = await Images.toBase64(file);
        this.editedItem.newLogoPreview = b64;
        this.editedItem.image = file;
      }
    },
  },
  async mounted() {
    await this.refreshData();
  },
};
</script>
